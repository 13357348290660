import { __decorate } from "tslib";
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
let LoginForm = class LoginForm extends Vue {
    onForgotPasswordBtnClick() {
        //
    }
    onSubmit() {
        //
    }
};
__decorate([
    PropSync('formData', { type: Object, required: true })
], LoginForm.prototype, "data", void 0);
__decorate([
    Prop({ type: Object, required: false })
], LoginForm.prototype, "errors", void 0);
LoginForm = __decorate([
    Component({
        name: 'LoginForm'
    })
], LoginForm);
export { LoginForm };
export default LoginForm;
