import AuthForm from '@profile/shared/components/AuthForm.vue';
/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const defaultKey = 'default';
/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const noAccessViews = {
    premium: AuthForm,
    [defaultKey]: AuthForm
};
