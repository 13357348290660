import { __decorate } from "tslib";
import { AuthServiceType, EventbusType, LocalStorage } from '@movecloser/front-core';
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from '@plugin/inversify';
import { Navbar } from '@component/Navbar';
import { AuthRepositoryType } from '../../contracts';
import { LoginForm } from '../../components/LoginForm.vue';
import { ConnectorErrors } from '@exception/connector-errors';
import { SubscriptionServiceType } from '@subscription/shared/services';
import { LoginIntention } from '@profile/intentions';
let AuthForm = class AuthForm extends Vue {
    constructor() {
        super(...arguments);
        this.errors = {};
        this.formData = {
            email: '',
            password: '',
            remember: false
        };
        this.isActing = false;
    }
    logIn() {
        this.isActing = true;
        const { email, password, remember } = this.formData;
        this.authRepository.logIn(new LoginIntention({
            email,
            password,
            remember
        }))
            .then((model) => {
            const user = model.getUser();
            this.authService.setUser(user);
            this.authService.setToken(model.toAuthToken());
            this.$store.dispatch('profile/setMe', user);
            this.subscriptionService.setSubscriptionData(user.subscription, user.subscriptionType, user.subscriptionPeriod);
            if (typeof window !== 'undefined' && typeof document !== 'undefined') {
                LocalStorage.set('d24-user-id', user.uniqueId);
                LocalStorage.set('d24-user-com-id', user.userComId);
                LocalStorage.set('d24-client-id', user.id);
                LocalStorage.set('d24-client-sub', user.subscriptionType);
            }
            this.eventBus.emit('auth:logged-in', user);
        })
            .catch((e) => {
            if (e.status === ConnectorErrors.Validation) {
                this.errors = e.payload;
            }
        })
            .finally(() => {
            this.isActing = false;
        });
    }
};
__decorate([
    Inject(AuthRepositoryType)
], AuthForm.prototype, "authRepository", void 0);
__decorate([
    Inject(EventbusType)
], AuthForm.prototype, "eventBus", void 0);
__decorate([
    Inject(AuthServiceType)
], AuthForm.prototype, "authService", void 0);
__decorate([
    Inject(SubscriptionServiceType)
], AuthForm.prototype, "subscriptionService", void 0);
AuthForm = __decorate([
    Component({
        name: 'AuthForm',
        components: {
            LoginForm,
            Navbar
        }
    })
], AuthForm);
export { AuthForm };
export default AuthForm;
