import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from '@plugin/inversify';
import { SiteServiceType } from '@service/site';
import { defaultKey, noAccessViews } from '../maps/no-access-views';
/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
let NoAccessView = class NoAccessView extends Vue {
    /**
     * Resolve 'no-access' component.
     *
     * @see 'modules/content/maps/no-access-views'
     */
    get noAccessComponent() {
        const viewToDisplay = `${this.site.getProperty('noAccessAction', defaultKey)}`;
        return noAccessViews[viewToDisplay];
    }
    /**
     * Actual resolved Site.
     */
    get site() {
        return this.siteService.getActiveSite();
    }
};
__decorate([
    Inject(SiteServiceType)
], NoAccessView.prototype, "siteService", void 0);
NoAccessView = __decorate([
    Component({
        name: 'NoAccessView'
    })
], NoAccessView);
export { NoAccessView };
export default NoAccessView;
